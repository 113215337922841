<template>
  <base-card>
    <v-card-title v-if="showTitle"> Add Product Skeleton </v-card-title>
    <v-card-text>
      <v-stepper v-model="stepperStep">
        <v-stepper-header>
          <v-stepper-step step="0" :complete="stepOneComplete">
            Basic Details
          </v-stepper-step>
          <v-stepper-step step="1" :complete="stepTwoComplete">
            Linked Product Skeletons
          </v-stepper-step>
          <v-stepper-step step="2" :complete="stepThreeComplete">
            Alert Settings
          </v-stepper-step>
          <v-stepper-step step="3" :complete="stepFourComplete">
            Price Settings
          </v-stepper-step>
          <v-stepper-step step="4" :complete="stepFiveComplete">
            Price Rules
          </v-stepper-step>
          <v-stepper-step step="5" :complete="stepSixComplete">
            Meta Data
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="0">
            <h4>Basic Details</h4>
            <trev-product-basic-details :supplier.sync="prodSkel.supplier" :name.sync="prodSkel.name"
              :division.sync="prodSkel.division" :skuNumber.sync="prodSkel.skuNumber"
              :description.sync="prodSkel.description" :resourceType.sync="prodSkel.resourceType"
              :xeroAccountCodeId.sync="prodSkel.defaultXeroAccountCodeId"
              :xeroTenantId.sync="prodSkel.defaultXeroTenantId">
            </trev-product-basic-details>
            <v-btn class="float-right" color="primary" @click="stepperStep++">
              Next
              <v-icon> mdi-chevron-right </v-icon>
            </v-btn>
          </v-stepper-content>
          <v-stepper-content step="1">
            <h4>Linked Products</h4>
            <trev-linked-product-skeleton-manage class="mb-2" :defaultSelectedProductSkeletons.sync="
              selectedLinkedProductSkeletons
            " :ProductSkeletonId="productSkeletonId">
            </trev-linked-product-skeleton-manage>
            <v-btn class="float-left" color="primary" @click="stepperStep--">
              <v-icon> mdi-chevron-left </v-icon>
              Previous
            </v-btn>
            <v-btn class="float-right" color="primary" @click="stepperStep++">
              Next
              <v-icon> mdi-chevron-right </v-icon>
            </v-btn>
          </v-stepper-content>
          <v-stepper-content step="2">
            <h4>Alert Settings</h4>
            <trev-product-alert-settings :alertOffSetInDays.sync="prodSkel.alertOffSetInDays"
              :alertGroup.sync="prodSkel.alertGroup" :hasAlert.sync="prodSkel.hasAlert"></trev-product-alert-settings>
            <v-btn class="float-left" color="primary" @click="stepperStep--">
              <v-icon> mdi-chevron-left </v-icon>
              Previous
            </v-btn>
            <v-btn class="float-right" color="primary" @click="stepperStep++">
              Next
              <v-icon> mdi-chevron-right </v-icon>
            </v-btn>
          </v-stepper-content>
          <v-stepper-content step="3">
            <h4>Price Settings</h4>
            <trev-product-price-settings :price.sync="prodSkel.price" :unitCharged.sync="prodSkel.unitCharged"
              :chargeFrequency.sync="prodSkel.chargeFrequency">
            </trev-product-price-settings>
            <v-btn class="float-left" color="primary" @click="stepperStep--">
              <v-icon> mdi-chevron-left </v-icon>
              Previous
            </v-btn>
            <v-btn class="float-right" color="primary" @click="stepperStep++">
              Next
              <v-icon> mdi-chevron-right </v-icon>
            </v-btn>
          </v-stepper-content>
          <v-stepper-content step="4">
            <h4>
              Price Rules<span v-if="prodSkel.hasPriceRules">
                - Base Price
                <span class="success--text">£{{ prodSkel.price }}</span></span>
            </h4>
            <trev-product-price-rules :unitCharged="prodSkel.unitCharged" :hasPriceRules.sync="prodSkel.hasPriceRules"
              :priceRules.sync="prodSkel.priceRules"></trev-product-price-rules>
            <v-btn class="float-left" color="primary" @click="stepperStep--">
              <v-icon> mdi-chevron-left </v-icon>
              Previous
            </v-btn>
            <v-btn class="float-right" color="primary" @click="stepperStep++">
              Next
              <v-icon> mdi-chevron-right </v-icon>
            </v-btn>
          </v-stepper-content>
          <v-stepper-content step="5">
            <h4>Meta Data</h4>
            <trev-product-meta-data :tags.sync="prodSkel.metaData"></trev-product-meta-data>
            <v-row class="mt-10">
              <v-col>
                <v-btn class="float-left" color="primary" @click="stepperStep--">
                  <v-icon> mdi-chevron-left </v-icon>
                  Previous
                </v-btn>
                <v-btn class="float-right" color="primary" @click="addProductSkeleton">
                  Save Product Skeleton
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
  </base-card>
</template>

<script>
import TrevProductAlertSettings from "../forms/trev-product-alert-settings.vue";
import trevProductBasicDetails from "../forms/trev-product-basic-details.vue";
import TrevProductPriceRules from "../forms/trev-product-price-rules.vue";
import TrevProductPriceSettings from "../forms/trev-product-price-settings.vue";
import TrevProductMetaData from "../forms/trev-product-metadata.vue";
import TrevLinkedProductSkeletonManage from "./trev-linked-product-skeleton-manage.vue";
export default {
  components: {
    trevProductBasicDetails,
    TrevProductAlertSettings,
    TrevProductPriceSettings,
    TrevProductPriceRules,
    TrevProductMetaData,
    TrevLinkedProductSkeletonManage,
  },
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
    companyId: {
      type: String,
      default: "",
    },
    productSkeletonId: {
      type: String,
      default: "",
    }, //This is for editing purposes
  },
  computed: {
    stepOneComplete() {
      return this.stepperStep > 0;
    },
    stepTwoComplete() {
      return this.stepperStep > 1;
    },
    stepThreeComplete() {
      return this.stepperStep > 2;
    },
    stepFourComplete() {
      return this.stepperStep > 3;
    },
    stepFiveComplete() {
      return this.stepperStep > 4;
    },
    stepSixComplete() {
      return this.stepperStep > 5;
    },
    hasPriceRule() {
      return this.prodSkel.hasPriceRules ? 0 : null;
    },
    hasAlert() {
      return this.prodSkel.hasAlert ? 0 : null;
    },
  },
  created() {
    if (this.productSkeletonId) {
      //get the prod skel
      this.getLinkedProductSkeletons();

      this.$courier.ProductSkeletons.getById(this.productSkeletonId).then(
        (skelly) => {
          this.prodSkel = skelly;

          this.$courier.ProductSkeletons.withId(this.productSkeletonId)
            .PriceRules.getData(1, 200, "")
            .then((priceRules) => {
              this.prodSkel.priceRules = priceRules.payload;
            });

          this.$courier.ProductSkeletons.withId(this.productSkeletonId)
            .MetaData.getData(1, 200, "")
            .then((metaData) => {
              this.prodSkel.metaData = metaData.payload;
            });
        }
      );
    }
  },
  methods: {
    async getLinkedProductSkeletons() {
      this.$courier.ProductSkeletons.withId(this.productSkeletonId)
        .LinkedProductSkeletons.getData(1, 9999, "")
        .then((dataResp) => {
          if (dataResp && dataResp.payload && dataResp.payload.length > 0) {
            this.selectedLinkedProductSkeletons = dataResp.payload;

            this.defaultSelectedLinkedProductSkeletons = JSON.parse(
              JSON.stringify(dataResp.payload)
            );
          }
        });
    },
    async addLinkedProductSkeletons(parentProductSkeletonId) {
      if (
        this.defaultSelectedLinkedProductSkeletons &&
        this.defaultSelectedLinkedProductSkeletons.length > 0
      ) {
        //remove all of it
        var removalTasks = [];
        for (const productId of this.defaultSelectedLinkedProductSkeletons.map(x => x.id)) {
          var t = this.$courier.ProductSkeletons.withId(
            parentProductSkeletonId
          ).LinkedProductSkeletons.removeById(productId);

          removalTasks.push(t);
        }

        await Promise.allSettled(removalTasks);
      }

      if (
        this.selectedLinkedProductSkeletons &&
        this.selectedLinkedProductSkeletons.length > 0
      ) {
        //compare the new linked products to the ones we retreived at the beginning
        for (const linkedProductId of this.selectedLinkedProductSkeletons) {
          //the linked product id doesnt exist in the product children already
          //get the productskeleton and add it
          var childSkeleton = await this.$courier.ProductSkeletons.getById(
            linkedProductId
          );

          //now add the child onto parent

          this.$courier.ProductSkeletons.withId(parentProductSkeletonId)
            .LinkedProductSkeletons.add(childSkeleton)
            .then(() => {
              //idk do something?
            });
        }
      }
    },
    async addProductSkeleton() {
      if (this.companyId) {
        this.prodSkel.parentCompanyId = this.companyId;
      }

      if (this.prodSkel.defaultXeroAccountCode && this.prodSkel.defaultXeroAccountCode.id) {
        this.prodSkel.defaultXeroAccountCode = this.prodSkel.defaultXeroAccountCode.code;
      }

      if (this.prodSkel.defaultXeroAccountCodeId && this.prodSkel.defaultXeroTenantId && this.prodSkel.defaultXeroAccountCodeId != '00000000-0000-0000-0000-000000000000') {
        this.prodSkel.defaultXeroAccountCode = await this.$courier.XeroAccountExternal.getById(this.prodSkel.defaultXeroAccountCodeId, [{
          key: 'tenantId',
          value: this.prodSkel.defaultXeroTenantId
        }]);
      }

      if (this.productSkeletonId) {
        //is edit

        this.$courier.ProductSkeletons.updateById(
          this.productSkeletonId,
          this.prodSkel
        ).then(async () => {
          await this.addLinkedProductSkeletons(this.productSkeletonId);
          this.$emit("updated-product-skeleton");
          this.stepperStep = 0;
          this.prodSkel = {
            skuNumber: "",
            name: "",
            description: "",
            division: "",
            price: 0,
            unitCharged: "",
            chargeFrequency: "",
            alertOffSetInDays: 0,
            alertGroup: "",
            hasAlert: false,
            hasPriceRules: false,
            priceRules: [],
            metaData: [],
            supplier: "",
            defaultXeroTenantId: "",
            defaultXeroAccountCode: "",
          };
        });
      } else {
        this.$courier.ProductSkeletons.add(this.prodSkel).then(
          async (newProdSkel) => {
            await this.addLinkedProductSkeletons(newProdSkel.id);
            this.$emit("added-product-skeleton", newProdSkel);
            this.stepperStep = 0;
            this.prodSkel = {
              skuNumber: "",
              name: "",
              description: "",
              division: "",
              price: 0,
              unitCharged: "",
              chargeFrequency: "",
              alertOffSetInDays: 0,
              alertGroup: "",
              hasAlert: false,
              hasPriceRules: false,
              priceRules: [],
              metaData: [],
              supplier: "",
              defaultXeroTenantId: "",
              defaultXeroAccountCode: "",
            };
          }
        );
      }
    },
  },
  data: () => ({
    stepperStep: 0,
    selectedLinkedProductSkeletons: [],
    defaultSelectedLinkedProductSkeletons: [],
    prodSkel: {
      skuNumber: "",
      name: "",
      description: "",
      division: "",
      price: 0,
      unitCharged: "",
      chargeFrequency: "",
      alertOffSetInDays: 0,
      alertGroup: "",
      hasAlert: false,
      hasPriceRules: false,
      priceRules: [],
      metaData: [],
      supplier: "",
      defaultXeroTenantId: "",
      defaultXeroAccountCode: "",
    },
  }),
};
</script>

<style>

</style>
