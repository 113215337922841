<template>
  <div>
    <v-switch
      label="Use Alerts"
      v-model="hasAlert2"
      @click="$emit('update:hasAlert', hasAlert2)"
    >
    </v-switch>
    <v-expansion-panels v-model="hasAlerts2" flat>
      <v-expansion-panel class="transparent">
        <v-expansion-panel-content>
          <v-slider
            v-model="alertOffSetInDays2"
            track-color="grey"
            always-dirty
            min="0"
            max="120"
            class="mt-8"
            @input="$emit('update:alertOffSetInDays', alertOffSetInDays2)"
          >
            <template v-slot:prepend>
              <v-text-field
                label="Days before expiry to Alert"
                v-model="alertOffSetInDays2"
                class="mt-0 pt-0"
                type="number"
                style="width: 200px"
                @keyup="$emit('update:alertOffSetInDays', alertOffSetInDays2)"
              >
              </v-text-field>
            </template>
          </v-slider>
          <v-text-field
            label="Alert Group"
            v-model="alertGroup2"
            @keyup="$emit('update:alertGroup', alertGroup2)"
          ></v-text-field>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  props: {
    hasAlert: {
      type: Boolean,
      default: false
    },
    alertOffSetInDays: {
      type: Number,
      default: 0
    },
    alertGroup: {
      type: String,
      default: ""
    }
  },
  computed: {
    hasAlerts2() {
      return this.hasAlert2 ? 0 : null;
    }
  },
  watch: {
    alertOffSetInDays(){
      this.alertOffSetInDays2 = this.alertOffSetInDays;
      this.alertGroup2 = this.alertGroup;
    },
    hasAlert: {
      immediate: true,
      handler(n, o) {
        this.hasAlert2 = this.hasAlert;
        this.alertOffSetInDays2 = this.alertOffSetInDays;
        this.alertGroup2 = this.alertGroup;
      }
    }
  },
  mounted() {},
  data: () => ({
    hasAlert2: false,
    alertOffSetInDays2: 0,
    alertGroup2: ""
  })
};
</script>

<style></style>
